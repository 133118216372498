<template>
  <div>
    <div style="display: flex">
      <el-button type="primary" @click="add(null)">创建</el-button>
    </div>
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      style="width: 100%; margin-top: 20px"
    >
      <el-tab-pane
        v-for="(item, index) in tabs"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <el-table :data="item.list" border :max-height="maxHeight" v-loading="loading">
          <el-table-column prop="id" label="领域ID" fixed="left"> </el-table-column>
          <el-table-column prop="icon" label="领域图标" align="center" width="100">
            <template slot-scope="scope">
              <el-popover placement="top" width="200" trigger="hover">
                <el-avatar :size="200" :src="scope.row.icon" />
                <el-avatar slot="reference" :src="scope.row.icon" />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="领域名称" width="150"> </el-table-column>
          <el-table-column prop="descriptions" label="领域描述" width="250">
            <template slot-scope="scope">
              <el-popover
                placement="top"
                width="280"
                trigger="hover"
                :content="scope.row.descriptions"
              >
                <span class="multi-line" slot="reference">{{
                  scope.row.descriptions
                }}</span>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="hot" label="是否热门">
            <template slot-scope="scope">
              {{ scope.row.hot ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column prop="hotSort" label="热门排序"> </el-table-column>
          <el-table-column prop="memberCount" label="成员数"> </el-table-column>
          <el-table-column prop="userId" label="领主ID" width="150"> </el-table-column>
          <el-table-column prop="userAvatar" label="领主头像" align="center" width="100">
            <template slot-scope="scope">
              <!-- <el-popover placement="top" width="200" trigger="hover"> -->
              <!-- <el-avatar :size="200" :src="scope.row.userAvatar" /> -->
              <el-avatar slot="reference" :src="scope.row.userAvatar" />
              <!-- </el-popover> -->
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="领主姓名"> </el-table-column>
          <el-table-column prop="company" label="领主公司"> </el-table-column>
          <el-table-column prop="jobTitle" label="领主职位"> </el-table-column>
          <el-table-column prop="isOpen" label="是否开启">
            <template slot-scope="scope">
              {{scope.row.isOpen?'开启':'关闭'}}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150" fixed="right">
            <template slot-scope="scope">
              <div style="display: flex; justify-content: space-evenly">
                <el-button size="mini" type="text" @click="detail(scope.row)"
                  >详情</el-button
                >
                <el-button
                  v-if="!scope.row.manager && scope.row.openPost"
                  size="mini"
                  type="text"
                  @click="post(scope.row)"
                  >发帖</el-button
                >
                <el-button
                  v-if="scope.row.manager"
                  size="mini"
                  type="text"
                  @click="add(scope.row)"
                  style="margin-left: 0"
                  >编辑</el-button
                >
                <el-dropdown
                  v-if="scope.row.manager"
                  style="margin-top: 2px"
                >
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="text"
                        @click="post(scope.row)"
                        >发帖</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="text"
                        @click="openMemberAudit(scope.row)"
                        >{{
                          scope.row.openMemberAudit ? "关闭成员审核" : "开启成员审核"
                        }}</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="text"
                        @click="openPost(scope.row)"
                        >{{
                          scope.row.openPost ? "关闭成员发帖" : "开启成员发帖"
                        }}</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        size="mini"
                        type="text"
                        @click="openPayment(scope.row)"
                        >{{ scope.row.openPayment ? "关闭付费" : "开启付费" }}</el-button
                      >
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                          size="mini"
                          type="text"
                          @click="toggleCircleStatus(scope.row)"
                      >{{ scope.row.isOpen ? "关闭圈子" : "开启圈子" }}</el-button
                      >
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="item.totalRows"
          :current-page="item.page"
          :page-size="20"
          style="margin-top: 20px"
          @current-change="handleChangePage"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FieldApi from "../../request/FieldApi";
import dayjs from "dayjs";
export default {
  data() {
    return {
      maxHeight: document.documentElement.clientHeight - 290,
      activeName: "create",
      tabs: [
        {
          label: "我创建的领域",
          name: "create",
          page: 1,
          list: [],
          totalRows: 0,
        },
        {
          label: "我加入的领域",
          name: "join",
          page: 1,
          list: [],
          totalRows: 0,
        },
      ],
      loading: false
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const { isSuperManager } = this.$store.getters.getUserInfo;
      const result = await FieldApi.list({
        pageNo: this.activeName === "create" ? this.tabs[0].page : this.tabs[1].page,
        pageSize: 20,
        manager: this.activeName === "create",
      });
      this.loading = false
      if (result.success) {
        if (this.activeName === "create") {
          this.tabs[0].list = result.data.map(item => {
            return {
              ...item,
              manager: isSuperManager ? isSuperManager : item.manager 
            }
          });
          this.tabs[0].totalRows = result.totalRows;
        } else {
          this.tabs[1].list = result.data;
          this.tabs[1].totalRows = result.totalRows;
        }
      }
    },
    handleChangePage(val) {
      if (this.activeName === "create") {
        this.tabs[0].page = val;
      } else {
        this.tabs[1].page = val;
      }
      this.loadData();
    },
    handleClick(tab) {
      this.activeName = tab.name;
      this.loadData();
    },
    formatTime(val) {
      return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
    },
    add(val) {
      if (val) {
        this.$router.push({
          path: "/field/add",
          query: { id: val.id },
        });
      } else {
        this.$router.push("/field/add");
      }
    },
    detail(val) {
      this.$router.push({
        path: "/field/detail",
        query: { id: val.id },
      });
    },
    post(val) {
      this.$router.push({
        path: "/field/posts/add",
        query: { circleId: val.id, isDetail: false },
      });
    },
    openMemberAudit(val) {
      if (val.openPayment && !val.openMemberAudit) {
        this.$message.info('已开启付费无需再开启成员审核')
        return
      }
      FieldApi.openMemberAudit({
        circleId: val.id,
        open: !val.openMemberAudit,
      }).then((res) => {
        if (res.success) {
          this.$message.success("修改成功");
          val.openMemberAudit = !val.openMemberAudit;
        }
      });
    },
    openPost(val) {
      FieldApi.openPost({
        circleId: val.id,
        open: !val.openPost,
      }).then((res) => {
        if (res.success) {
          this.$message.success("修改成功");
          val.openPost = !val.openPost;
        }
      });
    },
    toggleCircleStatus(val) {
      FieldApi.update({
        ...val,
        isOpen: !val.isOpen
      }).then((res) => {
        if (res.success) {
          this.$message.success("修改成功");
          val.openPost = !val.openPost;
        }
      });
    },
    openPayment(val) {
      if (val.openPayment) {
        FieldApi.openPayment({
          circleId: val.id,
          open: false,
        }).then((res) => {
          if (res.success) {
            this.$message("关闭付费成功");
            val.openPayment = false;
          }
        });
      } else {
        this.$prompt("请输入付费价格", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
          inputErrorMessage: "请输入正确的价格",
        })
          .then(({ value }) => {
            if (value) {
              FieldApi.openPayment({
                circleId: val.id,
                open: true,
                entryPrice: value * 100,
              }).then((res) => {
                if (res.success) {
                  this.$message.success("开启付费成功");
                  val.openPayment = true;
                }
              });
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
  font-weight: bold;
}
.el-icon-arrow-down {
  font-size: 10px;
  font-weight: bold;
}
</style>
